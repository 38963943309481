import styled from "styled-components";

export const Avatar = styled.img`
  width: ${props => props.large ? "48px" : "32px"};
  height: ${props => props.large ? "48px" : "32px"};
  margin-right: 12px;
  border-radius: 50%;
  
  @media screen and (max-width: 800px) {
    margin-right: 0;
  }

  ${props => {
    if (props.center) {
      return `
        align-self: center;
        justify-self: center;
    `
    }
  }
  }
`

export const EventBox = styled.div`
  z-index: 1;
  display: grid;
  grid-gap: 10px;
  grid-template-rows: auto max-content 5px auto;
  width: 100%;
  /*@media only screen and (max-width: 1100px) {
    grid-template-rows: auto max-content 5px auto;
  }*/
`

export const SpeakerWrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto;
  grid-gap: 8px 16px;
`

export const SpeakerDiv = styled.div`
  grid-template-columns: 40px 1fr;
  display: grid;
`

export const CardImg = styled.img`
  height: 200px;
  width: 100%;

  @media only screen and (max-width: 1100px) {
    height: 150px;
  }
`

export const Time = styled.p`
  color: white;
  font-weight: bold;
  background-image: ${props => props.image ? "url(" + props.image + ")" : ""};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: grid;
  align-content: end;
  padding: 5%;
  box-sizing: border-box;
  aspect-ratio: 16/9;
`